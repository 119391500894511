<template>
  <div>
    <base-header class="pb-6">
      <b-row aling-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
        <b-col cols="5" lg="6" class="text-right">
          <b-button @click="onExport" variant="info">
            <b-icon-download></b-icon-download>
            Export
          </b-button>
          <b-button @click="onImport" variant="light">
            <b-icon-upload></b-icon-upload>
            Import/Update Subscribers
          </b-button>
        </b-col>
      </b-row>
      <b-row class="pb-3">
        <b-col lg="8">
          <b-input-group>
            <b-input-group-prepend>
              <b-dropdown split class="dropdown-fix"
                          split-variant="secondary"
                          variant="secondary" :text="filterCriteriaList[this.searchField]">
                <b-dropdown-item v-for="item in Object.keys(filterCriteriaList)"
                                 @click="onFilterFieldChange(item)"
                                 :key="item">
                  {{ filterCriteriaList[item] }}
                </b-dropdown-item>
              </b-dropdown>
            </b-input-group-prepend>
            <b-input v-model="searchValue"></b-input>
            <b-input-group-append>
              <b-button variant="secondary" @click="listSubscribers">Search</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <b-row>
        <b-col>
          <b-card no-body>
            <b-card-header class="border-0">
              <h3 class="mb-0">Subscribers</h3>
            </b-card-header>
            <el-table :data="tableData" @sort-change="onSortChange"
                      row-key="id"
                      header-row-class-name="thead-light">
              <el-table-column prop="id">
                <template v-slot="{row}">
                  <el-dropdown trigger="click" class="dropdown">
                    <i class="fas fa-ellipsis-v mt-2"></i>
                    <el-dropdown-menu class="dropdown-menu show" slot="dropdown">
                      <b-dropdown-item @click="onSubscriberDetail(row.id)">Details
                      </b-dropdown-item>
                      <b-dropdown-item @click="onMakeTestUser(row)">Make Test User
                      </b-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
              <el-table-column label="Channel"
                               prop="fs"
                               min-width="120">
                <template v-slot="{row}">
                  <div class="d-flex align-items-center">
                    <div>
                      <b-img v-show="row.t" class="application-icon" v-b-tooltip.hover :title="row.t"
                             :src="'/img/icons/notification.svg'"/>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Platform"
                               prop="aid"
                               min-width="240">
                <template v-slot="{row}">
                  <div v-if="application(row.aid).applicationType !== 'WEB'">
                    <base-img-icon :svg="application(row.aid).applicationType"
                                   :title="application(row.aid).name"></base-img-icon>
                    {{ row.br }} {{
                      row.mn
                    }}
                  </div>
                  <div v-else-if="application(row.aid).applicationType === 'SHOPIFY'">
                    <base-img-icon :svg="application(row.aid).applicationType"
                                   :title="application(row.aid).name"></base-img-icon>
                    <base-img-icon :svg="row.os.toLowerCase()"
                                   :title="decodeEmptySpace(row.os) + ' '+ decodeEmptySpace(row.osv)"></base-img-icon>
                    <base-img-icon :svg="decodeEmptySpace(row.bn).toLowerCase()"
                                   :title="decodeEmptySpace(row.bn) + ' ' + decodeEmptySpace(row.bv)"></base-img-icon>
                  </div>
                  <div v-else>
                    <base-img-icon :svg="application(row.aid).applicationType"
                                   :title="application(row.aid).name"></base-img-icon>
                    <base-img-icon :svg="row.os.toLowerCase()"
                                   :title="decodeEmptySpace(row.os) + ' '+ decodeEmptySpace(row.osv)"></base-img-icon>
                    <base-img-icon :svg="decodeEmptySpace(row.bn).toLowerCase()"
                                   :title="decodeEmptySpace(row.bn) + ' ' + decodeEmptySpace(row.bv)"></base-img-icon>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Last Seen"
                               prop="ls"
                               min-width="200"
                               sortable>
                <template v-slot="{row}">
                  <div class="d-flex align-items-center">
                    <div>
                      {{ row.ls | formatISODateTime }}
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="First Seen"
                               prop="fs"
                               min-width="200"
                               sortable>
                <template v-slot="{row}">
                  <div class="d-flex align-items-center">
                    <div>
                      {{ row.fs | formatISODateTime }}
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Sessions"
                               prop="sc"
                               min-width="140"
                               sortable>
                <template v-slot="{row}">
                  <div class="d-flex align-items-center">
                    <div>
                      {{ row.sc | formatNumeric }}
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                v-bind="column"
              >
              </el-table-column>
            </el-table>
            <b-card-footer class="justify-content-center">
              <b-row>
                <b-col lg="2">
                  <el-select @change="handlePerPage"
                             class="select-primary pagination-select"
                             v-model="pagination.perPage"
                             placeholder="Per page"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </b-col>
                <b-col lg="5" offset="1">
                  <base-pagination align="center"
                                   class="pagination-no-border"
                                   :current="pagination.currentPage"
                                   :per-page="pagination.perPage"
                                   :total="pagination.total"
                                   @change="paginationChanged($event)"
                  >
                  </base-pagination>
                </b-col>
                <b-col lg="4" class="text-right">
                  <div class="">
                    <p class="card-category">
                      Showing {{ from + 1 }} to {{ to }} of {{ pagination.total | formatNumeric }} Subscribers
                    </p>
                  </div>
                </b-col>
              </b-row>
            </b-card-footer>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Option, Select } from 'element-ui';
import { mapGetters } from 'vuex';
import memberService from '../../services/members';
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb.vue';
import clientPaginationMixin from '@/views/Tables/PaginatedTables/clientPaginationMixin';
import BaseImgIcon from '@/components/BaseImgIcon.vue';

export default {
  name: 'SubscriberList',
  computed: {
    ...mapGetters(['application']),
  },
  mixins: [clientPaginationMixin],
  components: {
    BaseImgIcon,
    RouteBreadCrumb,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
  },
  data: () => ({
    searchValue: '',
    sortBy: 'ls',
    sortDesc: true,
    filters: {
      query: 'Kg==',
    },
    searchField: 'id',
    filterCriteriaList: {
      id: 'Subscriber Id',
      ek: 'External Key',
      em: 'Email',
      ph: 'Phone Number',
      ip: 'Ip Address',
    },
    tableData: [],
    tableColumns: [
      {
        prop: 'ek',
        label: 'External Key',
        minWidth: 200,
        sortable: false
      },
      {
        prop: 'id',
        label: 'Subscriber Id',
        minWidth: 300,
        sortable: false
      },
      {
        prop: 'dl',
        label: 'Language',
        minWidth: 120,
        sortable: false
      },
      {
        prop: 'tz',
        label: 'Time Zone',
        minWidth: 200,
        sortable: false
      },
      {
        prop: 'ph',
        label: 'Phone',
        minWidth: 120,
        sortable: false
      },
      {
        prop: 'em',
        label: 'Email',
        minWidth: 120,
        sortable: false
      },
    ],
  }),
  created() {
    this.listSubscribers();
  },
  methods: {
    decodeEmptySpace(value) {
      return value ? value.replaceAll('%20', ' ') : '';
    },
    onExport() {
    },
    onImport() {
    },
    handlePerPage(value) {
      this.pagination.perPage = value;
      this.listSubscribers();
    },
    paginationChanged(page) {
      this.pagination.currentPage = page;
      this.listSubscribers();
    },
    onFilterFieldChange(field) {
      this.searchField = field;
    },
    onSubscriberDetail(id) {
      this.$router.push('/audience/subscribers/' + id);
    },
    onSortChange({
      prop,
      order
    }) {
      debugger;
      this.sortBy = prop;
      this.sortDesc = order === 'descending';
      this.listSubscribers();
    },
    async listSubscribers() {
      this.filters.sort = this.sortBy;
      this.filters.sortDesc = this.sortDesc;
      if (this.searchValue !== '') {
        this.pagination.currentPage = 1;
        let searchExpression = `${this.searchField}:${this.searchValue}`;
        this.filters.query = btoa(searchExpression);
      } else {
        this.filters.query = 'Kg==';
      }
      this.filters.size = this.pagination.perPage;
      this.filters.page = this.pagination.currentPage - 1;
      const subscribers = await memberService.subscribers(this.filters);
      this.pagination.total = subscribers.total;
      this.tableData = subscribers.results;
    },
  },
};
</script>
<style>
.dropdown-fix {
  display: inline-flex;
}
</style>
