<template>
  <div>
    <!-- Header -->
    <div class="header bg-primary py-7 py-lg-8 pt-lg-9">
      <b-container>
        <div class="header-body text-center mb-3">
          <b-row class="justify-content-center">
          </b-row>
        </div>
      </b-container>
    </div>
    <!-- Page content -->
    <b-container class="mt--200 pb-3">
      <b-row class="justify-content-center">
        <b-col lg="5" md="7">
          <b-card no-body class="bg-secondary border-0 mb-0">
            <b-card-body class="px-lg-2 py-lg-2">
              <div class="text-center text-muted mb-4">
                <img src="/img/Preloader_3.gif"/>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: 'ViaToken',
  components: {},
  mounted() {
    this.logout();
    if (this.$route.query.token) {
      this.resolveToken(this.$route.query.token);
    } else {
      this.$router.push(this.$route.query.redirect || '/');
    }
  },
  data() {
    return {
      loginModel: {},
      error: undefined,
    };
  },
  methods: {
    ...mapActions(['updateAuthToken', 'logout', 'initialize']),
    resolveToken(token) {
      this.updateAuthToken(token)
        .then(() => {
          this.initialize()
            .then(() => {
              this.$router.push(this.$route.query.redirect || '/');
            });
        });
    },
  },
};
</script>
